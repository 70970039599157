// Pass wrapper to radio
let choices = document.querySelectorAll('.choice-wrapper');

let event = document.createEvent('HTMLEvents');
event.initEvent('change', true, false);

choices.forEach(function(choice,index) { 
    choice.addEventListener('click', function(e) {
        if(e.target.type != "radio") {
            if(this.querySelectorAll('input[type="radio"]:checked').length > 0){
                this.querySelector('input[type="radio"]').checked = false;
            } else {
                this.querySelector('input[type="radio"]').checked = true;
            }
            this.querySelector('input[type="radio"]').dispatchEvent(event);
        }
    });
});
    
var titles = document.getElementsByClassName('sticky-title');
if(titles.length) {
        titles[0].closest('.form-group').classList.add('sticky');
        titles[1].closest('.form-group').classList.add('sticky'); 
}

